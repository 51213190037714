import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import styled from 'styled-components/macro';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';

import * as appActions from '~/store/modules/app/app-actions';

import Documentosaida from './views/Documentosaida';
import Pedido from './views/Pedido';
import Orcamento from './views/Orcamento';

import ModalMenuTEF from '~/pages/TelaVenda/modal/ModalMenuTEF';
import ModalIdentificacaoPDV from '~/pages/TelaVenda/modal/ModalIdentificacaoPDV';
import ModalConfiguracao from '~/pages/PainelVenda/modal/ModalConfiguracao';
import ModalDownload from '~/pages/PainelVenda/modal/ModalDownload';
import FilaDocumentos from '~/components/FilaDocumentos';
import ErrorFallback from '~/components/Error';
import { isWeb } from '~/store/modules/app/app-selectors';

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
};

const TabContainer = styled(Paper)`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100% - 44px);
  margin: 16px;
  overflow: unset;
`;

const PainelVenda = () => {
  const dispatch = useDispatch();

  const tabIndex = useSelector(state => state.monitor.tabIndex);
  const { showModalIdentificacaoPDV, showModalConfiguracao } = useSelector(state => state.app);
  const { showModalMenuTef } = useSelector(state => state.app);

  const handleConfiguracaoTerminal = () => {
    dispatch(appActions.setShowmodalIdentificacaoPDV({ show: true }));
  };

  let terminal = localStorage.getItem('TERMINAL_PDV') || null;

  return (
    <ErrorBoundary fallbackRender={props => <ErrorFallback {...props} />}>
      {!terminal && !isWeb && (
        <Alert severity="error" variant="filled" style={{ margin: '16px' }}>
          <AlertTitle>AVISO</AlertTitle>
          <Mui.Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Mui.Typography variant="body1">
              Será necessário informar a identificação única do PDV, não é permitido adicionar novos
              documentos.
            </Mui.Typography>
            <Mui.Button onClick={handleConfiguracaoTerminal}>
              <Mui.Typography  style={{ marginRight: 8 }}>
                Alterar terminal
              </Mui.Typography>
              <MuiIcons.OpenInNew fontSize="small" />
            </Mui.Button>
          </Mui.Box>
        </Alert>
      )}

      <TabContainer>
        <TabPanel value={tabIndex} index={0}>
          <Orcamento key={0} />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <Pedido key={1} />
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <Documentosaida key={2} />
        </TabPanel>
      </TabContainer>

      {showModalIdentificacaoPDV?.show && <ModalIdentificacaoPDV />}
      {showModalConfiguracao?.show && <ModalConfiguracao />}
      {showModalMenuTef?.show && <ModalMenuTEF />}
      <ModalDownload />
      <FilaDocumentos />
      </ErrorBoundary>
  );
};

export default PainelVenda;
