import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import * as Mui from '@material-ui/core';
import * as MuiIcons from '@material-ui/icons';

import { CurrencyInputCustom } from '~/components/CustomInput';
import * as pdvActions from '~/store/modules/pdv/pdv-actions';
import currency from 'currency.js';
import toast from '~/services/toast';
import { selectorParametroAsBoolean } from '~/store/modules/auth/auth-selectors';

const ModalDesconto = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { venda, showModalDesconto } = useSelector(state => state.pdv);
  const [valorDesconto, setValorDesconto] = useState(venda?.valorDesconto);
  const [percentualDesconto, setPercentualDesconto] = useState(venda?.percentualDesconto);
  const parametroPF054 = useSelector(selectorParametroAsBoolean('PF054'))

  const verificaRegraDesconto = () => {
    if (parametroPF054) {
      const itensFiltrados = venda.itens.filter(item => {
        const produtoComRegraDesconto = item.fatRegradescontoProdutoId ?? false;
        const temModalRegra = (item?.produtoEstProduto?.modalRegra || item?.modalRegra) ?? false;
        const edicaoManualCalculado = temModalRegra ? produtoComRegraDesconto : item?.edicaoManual;
        const editado = edicaoManualCalculado && !(item.itemValorUnitario > item.produtoPrecoVenda);

        return (
          item.itemValorUnitario &&
          item.itemQuantidade &&
          !produtoComRegraDesconto &&
          !editado && !item.itemCancelado
        )
      });
      return itensFiltrados;
    } else {
      return venda.itens
    }
  };

  const changeDesconto = e => {
    const desconto = e.floatvalue;
    const itensParaDesconto = verificaRegraDesconto();
    const valorMercadoria = venda.itens.reduce(
      (total, item) => total + item.itemValorUnitario * item.itemQuantidade,
      0
    );

    const percentual = Number(
      currency((desconto / valorMercadoria) * 100, {
        precision: 2
      })
    );

    setValorDesconto(desconto);
    setPercentualDesconto(percentual);
  };

  const changeDescontoPercentual = e => {
    const percentual = e.floatvalue;
    const itensParaDesconto = verificaRegraDesconto();
    const valorMercadoria = venda.itens.reduce(
      (total, item) => total + item.itemValorUnitario * item.itemQuantidade,
      0
    );

    const desconto =
      percentual > 0
        ? Number(
            currency(valorMercadoria * (percentual / 100), {
              precision: 2
            })
          )
        : 0;
    setValorDesconto(desconto);
    setPercentualDesconto(percentual);
  };

  const handleSubmit = async () => {
    const itensParaDesconto = verificaRegraDesconto();
    if (itensParaDesconto.length > 0) {
      if (venda?.parcelas?.length > 0) {
        const { isConfirmed } = await toast.confirm(
          'Deseja alterar a forma de efetuar os pagamentos?',
          'Ao adicionar um desconto, irá remover os pagamentos anteriores',
          {
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
          }
        );

        if (isConfirmed) {
          dispatch(pdvActions.limparPagamentos());
          dispatch(pdvActions.selecionarMeioPagamento(null));
          dispatch(pdvActions.selecionarCondicaoPagamento(null));
          dispatch(pdvActions.setMultiplosPagamentos(false));
          dispatch(pdvActions.atualizarDesconto(valorDesconto, itensParaDesconto));
          handleClose();
        }
      } else if (parametroPF054) {
        dispatch(pdvActions.atualizarDesconto(valorDesconto, itensParaDesconto));
        handleClose();
      } else {
        dispatch(pdvActions.atualizarDescontoSemParams(valorDesconto));
        handleClose();
      }
    } else {
      toast.info(
        'Não é permitido aplicar desconto a produtos oriundos de regra ou tabela de venda ou que têm preço inferior ao preço de cadastro.'
      );
    }
  };

  const handleClose = () => {
    dispatch(pdvActions.setShowModalDesconto({ show: false }));
  };

  return (
    <>
      <Mui.Dialog
        open={showModalDesconto?.show}
        onKeyDown={key => {
          if (key.code === 'Escape') {
            handleClose();
          }
        }}
        PaperComponent={Paper}
        maxWidth={'md'}
      >
        <Mui.DialogTitle>
          <Mui.AppBar color="primary" position="static">
            <Mui.Toolbar>
              <Mui.Box>
                <Mui.Typography variant="h5" style={{ textTransform: 'upperCase' }}>
                  DESCONTO
                </Mui.Typography>
              </Mui.Box>
              <Mui.Box flexGrow={1} />

              <Mui.IconButton color="inherit" onClick={handleClose} aria-label="close">
                <MuiIcons.Close />
              </Mui.IconButton>
            </Mui.Toolbar>
          </Mui.AppBar>
        </Mui.DialogTitle>
        <Mui.DialogContent dividers style={{ maxWidth: 480 }}>
          <Mui.Box p={2} mb={2}>
            <Mui.Grid container spacing={2}>
              <Mui.Grid item md={7}>
                <Mui.Typography align="left" variant="caption">
                  Desconto (R$)
                </Mui.Typography>
                <Mui.TextField
                  value={valorDesconto ?? 0}
                  onChange={changeDesconto}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: <Mui.InputAdornment position="start">R$</Mui.InputAdornment>,
                    inputComponent: CurrencyInputCustom,
                    style: {
                      height: 54
                    }
                  }}
                />
              </Mui.Grid>
              <Mui.Grid item md={5}>
                <Mui.Typography align="left" variant="caption">
                  Desc.(%)
                </Mui.Typography>
                <Mui.TextField
                  value={percentualDesconto ?? 0}
                  onChange={changeDescontoPercentual}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputComponent: CurrencyInputCustom,
                    style: {
                      height: 54
                    }
                  }}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Box>
        </Mui.DialogContent>
        <Mui.DialogActions>
          <Mui.Button size="large" onClick={handleSubmit} variant="contained" color="primary">
            <MuiIcons.CheckCircle /> <span style={{ paddingLeft: 8 }}>CONFIRMAR</span>
          </Mui.Button>

          <Mui.Button startIcon={<MuiIcons.Close />} size="large" onClick={handleClose}>
            CANCELAR (ESC)
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>
    </>
  );
};

export default ModalDesconto;
